import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "../content/CSS/Home.css";
import HeroImage from "../content/IMG/Home/Hero Illustration.jpg";

const Home = () => (
  <Fragment>
    <div className="home">
      <h1>Welcome to the External Request Center</h1>
      <p>
        The External Request Center manages requests pertaining to Applied
        Medical corporate sites or external-facing applications.
      </p>

      <div className="home--image-container">
        <img src={HeroImage} alt="Hero" />
      </div>

      <div>
        <p style={{ marginBottom: "0px" }}>
          Create a new request or issue to get started.
        </p>
        <p>Check progress on current request.</p>
        <div className="home--button-flex">
          <Link to={"/request"}>
          <button>Create New Request</button>
          </Link>
          {/* <button>Create New Request</button> */}
          {/* <Link to={"/request/overview"}>
          <button>View Submitted Request</button>
          </Link> */}
          <button>View Submitted Request</button>
          {/* <Link to={"/issue"}>
          <button>Report Issue</button>
          </Link> */}
          <button>Report Issue</button>
        </div>
      </div>

      <p>
        For assistance, please contact{" "}
        <a href="mailto:WebRequests@appliedmedical.com">
          External Web Development Team
        </a>
      </p>
    </div>
  </Fragment>
);

export default Home;

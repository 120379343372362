import React, {useState} from "react";
import "../content/CSS/Request.css";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Form, FormGroup, Input, Label, Row } from 'reactstrap';

const Request = () => {
  const date = new Date().toLocaleDateString();
  const [isSelected, setIsSelected] = useState(false);


  const handleChange = (event) => {
    if (event.target.value === "yes") {
      setIsSelected(!isSelected);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // const form = event.target;
    // const formData = new FormData(form);
    // const data = Object.fromEntries(formData.entries());
    // console.log(data);
    // form data is visible on console as object but does not include multiple selection
    // console.log([...formData.entries()]);
    // form data is visible on console as multiple arrays to allow for multiple selection
  }


  return (
    <div className="request">
      <section>
        <div className="request--header">
          <FontAwesomeIcon icon={faPencilAlt} />
          <h4>Request Details</h4>
        </div>

        <hr></hr>

        <div className="request--details">
          <div className="requestor">
            <p>Requestor:</p>
            <p>Jane Doe</p>
          </div>
          <div className="date">
            <p>Request Date:</p>
            <p>{date}</p>
          </div>
        </div>
      </section>

      <hr></hr>

      <section>
        <form className="request--overview" onSubmit={handleSubmit}>
          <div className="request--subheader">
            <h5>Project Overview</h5>
          </div>

          <div className="form-group">
            <label htmlFor="projectTitle">
              Project Title:<sup>*</sup>
            </label>
            <input name="projectTitle" id="projectTitle" required />
          </div>

          <div className="form-group">
            <label htmlFor="discussedWithTeamLead">
              I have discussed this project with my team leader.<sup>*</sup>
            </label>
            <select name="discussedWithTeamLead" id="discussedWithTeamLead" defaultValue="" required>
              <option value="" disabled></option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="projectDescription">
              Provide a brief description about this request.<sup>*</sup>
            </label>
            <textarea name="projectDescription" id="projectDescription" rows={4} cols={60} required />
          </div>

          <div className="form-group">
            <label htmlFor="projectReason">
              Provide a brief description of <b>why</b> the changes are
              occuring.
              <sup>*</sup>
            </label>
            <textarea name="projectReason" id="projectReason" rows={4} cols={60} required />
          </div>

          <br />
          <hr></hr>

          <div className="request--subheader">
            <h5>Corresponding URLS</h5>
          </div>

          <div className="form-group">
            <div className="form-group--column">
              <label htmlFor="webpageDescription">Webpage Description</label>
              <textarea name="webpageDescription" id="webpageDescription" rows={1} />
            </div>

            <div className="form-group--column">
              <label htmlFor="webpageURL">URL</label>
              <textarea name="webpageURL" id="webpageURL" rows={1} />
            </div>
          </div>

          <br />
          <hr></hr>

          <div className="request--subheader">
            <h5>Global Impact</h5>
          </div>

          <div className="form-group">
            <label htmlFor="globalImpact">
              Will the change(s) affect a similar webpage in another region?
              <sup>*</sup>
              <br />
              <i>
                Including appliedmedical.com (US), appliedmedical.eu (EU),
                appliedmedical.co.jp (Japan), and/or appliedmedical.mx (Mexico)
              </i>
            </label>
            <select name="globalImpact" id="globalImpact" defaultValue="" required onChange={handleChange}>
              <option value="" disabled></option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          {/* if yes, additional global websites listed */}

          {isSelected && 
           <div className="form-group">
           <label htmlFor="sitesAffected">Which global websites are affected?</label>
           <div className="form--checkboxes">
             <div>
               <input
                 type="checkbox"
                 name="sitesAffected"
                 id="sitesAffected"
                 value="appliedmedical.com"
                 className="form--checkboxes--input"
               />
               <span className="checkbox--label">appliedmedical.com (US)</span>
             </div>
             <div>
               <input
                 type="checkbox"
                 name="sitesAffected"
                 id="sitesAffected"
                 value="appliedmedical.eu"
                 className="form--checkboxes--input"
               />
               <span className="checkbox--label">appliedmedical.eu (EU)</span>
             </div>
             <div>
               <input
                 type="checkbox"
                 name="sitesAffected"
                 id="sitesAffected"
                 value="appliedmedical.co.jp"
                 className="form--checkboxes--input"
               />
               <span className="checkbox--label">
                 appliedmedical.co.jp (JP)
               </span>
             </div>
             <div>
               <input
                 type="checkbox"
                 name="sitesAffected"
                 id="sitesAffected"
                 value="appliedmedical.mx"
                 className="form--checkboxes--input"
               />
               <span className="checkbox--label">appliedmedical.mx (MX)</span>
             </div>
             <div>
               <input
                 type="checkbox"
                 name="sitesAffected"
                 id="sitesAffected"
                 value="other"
                 className="form--checkboxes--input"
               />
               <span className="checkbox--label">other</span>
             </div>
           </div>
         </div>
          }

          <br />
          <hr></hr>

          <div className="request--subheader">
            <h5>Project Impact</h5>
          </div>
          <div className="form-group form-group--2">
            <div>
              <div className="form--checkboxes">
                <p>Product Related:</p>
                <div>
                  <input
                    type="checkbox"
                    name="productRelated"
                    value="PSM update"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    Related to PSM updates
                  </span>
                </div>

                <div>
                  <input
                    type="checkbox"
                    name="productRelated"
                    value="new market"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    Impacts a new market for Applied
                  </span>
                </div>

                <div>
                  <input
                    type="checkbox"
                    name="productRelated"
                    value="new product"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    New product launch or line extension
                  </span>
                </div>
              </div>
            </div>

            <div>
              <div className="form--checkboxes">
                <p>Compliance Related:</p>
                <div>
                  <input
                    type="checkbox"
                    name="complianceRelated"
                    value="legal non-compliance"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    Potential risk of legal non-compliance
                  </span>
                </div>

                <div>
                  <input
                    type="checkbox"
                    name="complianceRelated"
                    value="regulatory non-compliance"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    Potential risk of regulatory non-compliance or security
                    breach
                  </span>
                </div>

                <div>
                  <input
                    type="checkbox"
                    name="complianceRelated"
                    value="new product"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    New product launch or line extension
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group form-group--2">
            <div>
              <div className="form--checkboxes">
                <p>Tradeshow Related:</p>
                <div>
                  <input
                    type="checkbox"
                    name="tradeshowRelated"
                    value="national event"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    National events or tradeshows
                  </span>
                </div>

                <div>
                  <input
                    type="checkbox"
                    name="tradeshowRelated"
                    value="regional event"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    Regional events or tradeshows
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="form--checkboxes">
                <p>Other:</p>
                <div>
                  <input
                    type="checkbox"
                    name="other"
                    value="senior management"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    Senior Management requested and reviewed (VP-level and
                    above)
                  </span>
                </div>

                <div>
                  <input
                    type="checkbox"
                    name="other"
                    value="feasibility"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    Requires a feasibility or discovery meeting (some aspects of
                    the project are not clearly defined)
                  </span>
                </div>

                <div>
                  <input
                    type="checkbox"
                    name="other"
                    value="administrative change"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    Administrative change (email address, phone number, date,
                    link updates, minor grammatical corrections)
                  </span>
                </div>

                <div>
                  <input
                    type="checkbox"
                    name="other"
                    value="major error"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    Major technical errors
                  </span>
                </div>

                <div>
                  <input
                    type="checkbox"
                    name="other"
                    value="webpage layout"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    Requires a webpage layout update
                  </span>
                </div>

                <div>
                  {/* <input
                    type="checkbox"
                    name="other"
                    value="other impact"
                    className="form--checkboxes--input"
                  />
                  <span className="checkbox--label">
                    Other (Please specify below)
                  </span> */}
                  <label htmlFor="otherProjectImpact" className="checkbox--label">Other</label>
                  <br />
                  <textarea
                    rows={4}
                    cols={55}
                    name="otherProjectImpact"
                    id="otherProjectImpact"
                    placeholder="Please specify other project impact"
                  />
                </div>
              </div>
            </div>
          </div>

          <br />
          <hr></hr>

          <div className="request--subheader">
            <h5>Priority Level and Due Date</h5>
            <p>
              The table below contains the criteria of project priorities. Based
              off of the below definitions, due dates may be subject to change.
            </p>
          </div>

          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Priority Level</th>
                <th scope="col">Definition</th>
                <th scope="col">Example Projects</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Critical</th>
                <td>
                  Requests are urgent and have a critical business impact
                  <br />
                  <br />
                  Without it, we cannot meet our promise to our customers, or
                  customers will not recognize Applied's core values, or we are
                  not meeting compliance or security requirements
                </td>
                <td>
                  <ul>
                    <li>
                      Maintenance to SAP function which updates IFU search on
                      website
                    </li>
                    <li>Resolving coding bugs on the "Idea Submission Form"</li>
                    <li>Adding a DEI section under "Who We Are"</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th scope="row">Important</th>
                <td>
                  Requests that have a major business impact.
                  <br />
                  <br />
                  Without it, our customers will be misinformed about Applied's initiatives, products, and functions.
                </td>
                <td>
                  <ul>
                    <li>Maintaining the Event section of vNOTES.com</li>
                    <li>Editing incorrect product claims on a product page</li>
                    <li>Maintaining and improving the "Find a Surgeon" directory on vNOTES.com</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th scope="row">Impactful</th>
                <td>
                  Requests that enhance the efficiency and effectiveness of Applied's online presence.
                  <br/>
                  <br/>
                  Without it, our customers will be subject to a poor user experience, or our customer's perception of Applied is out-of-date.
                </td>
                <td>
                  <ul>
                    <li>Updating the layout of product pages for formatting improvements</li>
                    <li>Updating the dates of engineering internship programs</li>
                    <li>Updating contact information for Applied Corporate offices</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th scope="row">Nice-to-Have</th>
                <td>
                  Requests that enhance Applied's ancillary website functions.
                  <br/>
                  <br/>
                  Without it, we are missing opportunities to design our website to optimize preferences.
                </td>
                <td>
                  <ul>
                    <li>Including parallax scolling functionality on webpages</li>
                    <li>Adding additional filtering functions to the Find a Surgeon directory on vNOTES.com</li>
                    <li>Uploading CSM assets, such as banner images</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="form-group form-group--3">
            <label style={{width: "auto"}} htmlFor="dueDate">Requested Due Date:<sup>*</sup></label>
            <input name="dueDate" id="dueDate" type="date" required></input>
          </div>

          <br />
          <hr></hr>

          <div className="request--subheader">
            <h5>Attachments</h5>
            <ul>
              <li>Upload file(s)</li>
              <li>Redline file(s)</li>
              <li>Supporting documents, such as translation certificate, master redlines, and branding request forms</li>
            </ul>
          </div>

          <div className="form-group form-group--3">
            <input type="file" name="requestAttachments" accept="image/*, .pdf, doc" multiple></input>
          </div>
         
          <p style={{paddingLeft: "25px"}}>Use (shift + click) or (ctrl + click) to multi-select files</p>

          <br />
          <hr></hr>

          <div className="button-container">
            <button type="submit">Submit</button>
            <button style={{backgroundColor: "#9b3030"}} type="reset" onClick={() => setIsSelected(false)}>Cancel</button>
          </div>

        </form>
      </section>
    </div>
  );
};

export default Request;
